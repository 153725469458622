<template>
  <transition name="scroll-x-transition">
    <v-alert v-if="installAppPrompt"
             class="rounded-lg pa-4"
             color="primaryDark"
             dark
             dense
             max-width="500"
             prominent
             @click.native="installAppPrompt = null">
      <div class="d-flex align-center">
        <v-avatar color="#fff">
          <img v-if="business.logo.thumb !== ''"
               :alt="business.slug"
               :lazy-src="require('@/assets/loading.svg')"
               :src="business.logo.thumb"/>
          <span v-else
                class="text-h3 font-weight-medium"
                style="color: #00999a">
            {{ getFirstCharacter(business.name) }}
            </span>
        </v-avatar>
        <div class="pl-3 pr-1">
          <h3>{{ business.name }}</h3>
          <p class="mb-0">Agregar acceso directo a este negocio desde tu pantalla principal</p>
        </div>
        <v-btn class="text-none"
               color="secondary"
               depressed rounded
               @click="showInstallAppPrompt">
          Agregar
        </v-btn>
      </div>
    </v-alert>
  </transition>
</template>

<script>
import {getFirstChar} from "@/utils/data";

export default {
  name: 'AppInstallationButton',
  data: () => ({
    installAppPrompt: null,
  }),
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setTimeout(() => {
        this.installAppPrompt = e;
        setTimeout(() => {
          this.installAppPrompt = null;
        }, 20 * 1000);
      }, 30 * 1000);
    })
    window.addEventListener('appinstalled', () => {
      this.installAppPrompt = null;
    });
  },
  methods: {
    getFirstCharacter: getFirstChar,
    showInstallAppPrompt: async function () {
      this.installAppPrompt.prompt();
      await this.installAppPrompt.userChoice;
      this.installAppPrompt = null;
    },
  },
  props: ['business']
}
</script>

<style scoped>
.v-alert {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 10px;
  right: 10px;
}
</style>
